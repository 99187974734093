<template>
  <div class="mt-3 mb-4" style="width: 100%">
    <b-row>
      <b-col cols="12">
        <h3>
          Formular Designer | {{ currentForm.formName }} <span v-if="definitionName == 'formDefintion'">&nbsp; | Wizard </span
          ><span v-else-if="definitionName == 'formAfterDefintion'">&nbsp; | Folgeverarbeitung</span><span v-else>&nbsp; | Customizing</span>
        </h3>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="displayType" :options="items"> </b-form-select>
      </b-col>

      <b-col cols="10">
        <b-btn @click="saveForm(true)" variant="success" class="mr-5">Speichern</b-btn>
        <b-btn @click="cancel(true)" class="mr-5">Abbrechen</b-btn>

        <b-button @click="showRevision = !showRevision">Revision anzeigen</b-button>
      </b-col>
    </b-row>
    <div id="builder" v-show="loadedBuilder" style="width: 100%"></div>

    <b-modal v-model="showRevision" size="xl" modal-class="modal-fullscreen">
      <b-container fluid>
        <b-row>
          <b-col cols="2">
            <h6 v-if="revisions.length > 0"> Letzte Revision ({{ revisions.length }} von max 250); </h6>
            <ul class="folder main-template-tree" v-if="revisions.length > 0">
              <li v-for="(item, index) in revisions">
                <b-row>
                  <b-col cols="12">
                    <span @click="loadRevision(item, index)" style="cursor: pointer; display: inline-block" :title="item.revisionId">
                      <b-icon-file-richtext-fill></b-icon-file-richtext-fill>
                      ({{ item.revisionId }}) {{ item.created_at | formatDateTime }} <br /><small>({{ item.creationUser }})</small>
                    </span>
                  </b-col>
                </b-row>
              </li>
            </ul>
            <span v-else>
              <b-spinner label="Loading..."></b-spinner>
            </span>
          </b-col>

          <b-col cols="10">
            <div>
              <h3
                >Revision für: {{ currentForm.formName }} | Aktuelle Revision: {{ currentRevision.revisionId }} |
                {{ currentRevision.created_at | formatDateTime }}
                <b-spinner label="Loading..." v-if="loading"></b-spinner>
              </h3>
            </div>
            <div id="builder_rev" style="width: 100%; height: 1100px"> </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import config from '@/config/config.js';

import axios from 'axios';
import moment from 'moment';
import { Formio } from 'formiojs';


import FormioContrib from '@formio/contrib';
Formio.use(FormioContrib)

import '@/components/formio/SummaryComponent.js';
import '@/components/formio/ResetButtonComponent.js';
import '@/components/formio/CustomEditGridComponent.js';
import '@/components/formio/CustomCollectionSelectComponent.js';
import '@/components/formio/CustomDataSelectComponent.js';

//import '@/components/formio/checkoutComponent.js';

export default {
  name: 'FormBuilderDialog',

  data: () => ({
    rangeValue: 0,
    showRevision: false,

    formData: null,
    baseFormData: null,
    mappedPath: null,
    formTitle: null,
    currentForm: {},
    displayType: 'form',
    definition: {},
    definitionName: '',
    collectionValue: {},
    formio: null,
    loadedBuilder: false,
    loading: false,
    items: [
      { text: 'Einfaches Formular', value: 'form' },
      { text: 'Geführtes Formulare', value: 'wizard' },
    ],
    revisions: [],
    currentRevision: {},
  }),
  mounted() {
    let self = this;

    if (this.$route.params.id != undefined) {
      self.definitionName = this.$route.params.identifier;
      axios.get(config.API_URL + '/api/form/' + self.$route.params.id).then((response) => {
        self.collectionValue = response.data;
        self.currentForm = JSON.parse(response.data.value);
        let type =
          self.definitionName == 'formDefintion' ? 'Wizard' : self.definitionName == 'formAfterDefintion' ? 'Folgeverarbeitung' : 'Customizing';
        document.title = type + ' | ' + self.currentForm.formName + ' | FormBuilder  ';

        if (self.currentForm[self.definitionName] == null || self.currentForm[self.definitionName] == undefined) {
          self.currentForm[self.definitionName] = {
            display: 'wizard',
            components: [],
          };
        }

        self.displayType = 'wizard';
        if (response.data.value != null && self.currentForm[self.definitionName] != null) {
          if (typeof self.currentForm[self.definitionName] === 'string' && self.currentForm[self.definitionName] != '') {
            self.displayType = JSON.parse(self.currentForm[self.definitionName]).display;
          } else {
            self.displayType = self.currentForm[self.definitionName].display;
          }
        }

        if (!self.loadedBuilder) {
          self.loadBuilder();
        }
      });
    }
  },
  methods: {
    cancel() {
      if (confirm('Möchten Sie wirklich abbrechen? Ihre Eingaben werden dann nicht speichern!') == true) {
        window.location.reload();
      } else {
      }
    },
    saveForm(showSaved = false) {
      let self = this;

      axios.get(config.API_URL + '/api/form/' + self.$route.params.id).then((response) => {
        let liveValue = response.data;
        let liveForm = JSON.parse(response.data.value);

        liveForm[self.definitionName] = self.currentForm[self.definitionName];
        liveValue.value = JSON.stringify(liveForm);
        axios
          .put(config.API_URL + '/api/form/' + self.$route.params.id, liveValue, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            //     self.loadBuilder();
            if (showSaved) {
              self.$root.$children[0].showMessage('Gespeichert', 'Daten gespeichert', 'success');
            }
          });
      });
    },

    loadBuilder() {
      let self = this;

      self.definition = '';
      if (typeof self.currentForm[self.definitionName] === 'string') {
        self.definition = JSON.parse(self.currentForm[self.definitionName]);
      } else {
        self.definition = self.currentForm[self.definitionName];
      }

      Formio.setBaseUrl(config.API_URL + '/api/formManager');
      Formio.setProjectUrl(config.API_URL + '/api/formManager');
      let formioOptions = {
        alwaysConfirmComponentRemoval: true,
        i18n: {},
      };

      self.loadedBuilder = true;

      if (self.formio == null) {
        self.formio = Formio.builder(document.getElementById('builder'), self.definition, formioOptions).then(function (builder) {
          builder.on('saveComponent', function () {
            self.currentForm[self.definitionName] = builder.schema;

            self.saveForm();
          });

          builder.on('addComponent', () => {
            self.currentForm[self.definitionName] = builder.schema;

            //      self.saveForm();
          });
          builder.on('removeComponent', (component, schema, path, index) => {
            if (schema.type == 'form') {
              builder.schema.components = builder.schema.components.filter(function (returnableObjects) {
                return returnableObjects.title !== component.title;
              });
              let ind = builder.schema.components.map((item) => item.title).indexOf(component.title);
              self.currentForm[self.definitionName].components.splice(ind, 1);
              self.loadBuilder();
              //  self.currentForm[self.definitionName] = builder.schema
            } else {
              self.currentForm[self.definitionName] = builder.schema;
            }

            //      self.saveForm();
          });
          builder.on('updateComponent', () => {
            self.currentForm[self.definitionName] = builder.schema;

            //      self.saveForm();
          });
        });
        let query = {};
        query.query = "usf_RevisionForEntry('form'," + self.$route.params.id + ')';
        axios.post(config.API_URL + '/api/dynamicQuery', query).then((response) => {
          let collectionData = JSON.parse(response.data);

          self.revisions = collectionData;
          self.rangeValue = self.revisions.length - 1;
        });
      }
    },
    loadRevision(revision, index) {
      let self = this;
      let old_value = JSON.parse(revision.old_value);
      self.currentRevision = revision;

      let value = old_value[self.definitionName];

      self.loading = true;

      Formio.builder(document.getElementById('builder_rev'), value).then((builder) => {
        // Here is the form builder instance.
        self.loading = false;
      });
    },
  },

  watch: {
    displayType(val) {
 /*     let self = this;
      let definition = '';
      if (typeof self.currentForm[self.definitionName] === 'string') {
        definition = JSON.parse(self.currentForm[self.definitionName]);
      } else {
        definition = self.currentForm[self.definitionName];
      }
      definition.display = val;

      self.currentForm[self.definitionName] = definition;

      self.loadBuilder();*/
    },
  },
};
</script>

<style src="formiojs/dist/formio.full.min.css"></style>

<style>
.v-application .formio-form ul {
  padding-left: 0px;
}

.main-template-tree {
  max-height: 1000px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>

<style>
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
</style>
