import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=961f6826&"
import script from "./FormBuilder.vue?vue&type=script&lang=js&"
export * from "./FormBuilder.vue?vue&type=script&lang=js&"
import style0 from "formiojs/dist/formio.full.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./FormBuilder.vue?vue&type=style&index=1&id=961f6826&prod&lang=css&"
import style2 from "./FormBuilder.vue?vue&type=style&index=2&id=961f6826&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports