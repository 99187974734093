<template>
  <div>
    <b-col cols="12">
      <div>
        <b-row>
          <b-col cols="11">
            <h2 id="navigation-title">{{ activeForm.formName }}</h2>
          </b-col>

          <b-col class="text-right" v-if="activeForm.helpLink && showLink">

            <a :href="baseHelpLink + '' + activeForm.helpLink" target="_blank" class="mt-4">
              <b-icon icon="question-circle" style="width: 30px; height: 30px;"></b-icon>

            </a>


          </b-col>
          <b-col class="text-right" v-else>
            Suche Dokumentation ...
          </b-col>

        </b-row>

        <b-row>
          <b-col>
            <div id="renderer"></div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import { Formio } from "formiojs";

import config from "../config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      formId: 0,
      communityGUID: "",
      communityId: 0,
      currentFormData: {},
      activeForm: {},
      showLink: false,
      baseHelpLink: 'https://docs.ozg-manager.site/',

    };
  },
  mounted() {
    let self = this;

    self.$localforage.getItem("ozg_doku").then((data) => {
      if (data && data != null && data != "") {

        self.baseHelpLink = data
      } else {

        if (self.$root.$children[0].communityData.globalConfiguration && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != null && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != undefined) {

          self.baseHelpLink = self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl

        }
      }
    });
    if (
      self.$route.params.id != undefined &&
      self.$route.params.identifier != undefined
    ) {
      self.formId = self.$route.params.id;
      self.communityGUID = self.$route.params.identifier;


      window.formId = self.formId;
      axios
        .get(config.API_URL + "/api/form/" + self.formId)
        .then((response) => {

          self.$localforage.getItem("ozg_doku").then((data) => {
            if (data && data != null && data != "") {

              self.baseHelpLink = data
              self.showLink = true;


            } else {
              if (self.$root.$children[0].communityData.globalConfiguration && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != null && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != undefined) {

                self.baseHelpLink = self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl
                self.showLink = true;


              }
            }
          });
          self.currentFormData = response.data;
          self.activeForm = JSON.parse(
            response.data.value
          );

          self.defItem = JSON.parse(
            response.data.value
          ).formCustomizingDefintion;
          self.showForm = true;
          Formio.setBaseUrl(config.API_URL + "/api/formManager");
          Formio.setProjectUrl(
            config.API_URL + "/api/formManager"
          );

          let translation = self.$root.$children[0].translations;

          self.currentForm = Formio.createForm(
            document.getElementById("renderer"),
            self.defItem,
            {
              language: "de",
              i18n: translation,
              editors: {
                ckeditor: {
                  settings: {
                    language: {
                      // The UI will be in English.
                      ui: 'de',

                      // But the content will be edited in Arabic.
                      content: 'de'
                    }
                  }

                }
              }
            }
          )
            .then(function (form) {
              axios
                .get(config.API_URL + "/api/formCustomizingForCommunityId/" + self.formId)
                .then((response) => {


                  if (response != undefined) {
                    if (response.data.value != null) {





                      self.currentFormData.formValueId = response.data.formValueId
                      self.currentFormData.formValueData = response.data.value
                      form.submission = {
                        data: JSON.parse(response.data.value).data,
                      };

                    }
                  }
                }).catch((e) => {
                  form.submission = {
                    data: {},
                  };
                });

              self.$localforage.getItem("ozg_doku").then((data) => {
                if (data && data != null && data != "") {


                  self.baseHelpLink = data
                  self.showLink = true;

                }
                else {
                  if (self.$root.$children[0].communityData.globalConfiguration && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != null && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != undefined) {

                    self.baseHelpLink = self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl
                    self.showLink = true;



                  }
                }
              });
              self.viewListComponentKey = moment().format("x");
              form.on("submit", function (submission) {
                let formData = JSON.parse(self.currentFormData.value);

                let formValue = {};
                formValue.value = JSON.stringify(submission);
                formValue.collectionValueId = self.formId;
                formValue.formName = JSON.parse(self.currentFormData.value).formName;
                formValue.communityId =
                  self.$root.$children[0].userData.communityId;
                formValue.definitionName = "formCustomizingDefintion";
                formValue.created_at = moment()
                if (
                  self.currentFormData.formValueId != null &&
                  self.currentFormData.formValueId != undefined
                ) {
                  formValue.formValueId = self.currentFormData.formValueId;
                  axios
                    .put(
                      config.API_URL +
                      "/api/formValue/" +
                      self.currentFormData.formValueId,
                      formValue
                    )
                    .then((response) => {
                      self.$root.$children[0].showMessage(
                        "Gespeichert",
                        "Daten gespeichert",
                        "success"
                      );
                    });
                } else {
                  axios
                    .post(config.API_URL + "/api/formValue", formValue)
                    .then((response) => {
                      self.$root.$children[0].showMessage(
                        "Gespeichert",
                        "Daten gespeichert",
                        "success"
                      );
                    });
                }
              });
            })
            .catch((e) => {
              console.log(e);
            });
        });
    }
  },
};
</script>
