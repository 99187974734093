import { Formio } from "formiojs";
import Component from "formiojs/components/_classes/component/Component";


const FieldComponent = Formio.Components.components.field;
const editForm = Formio.Components.components.nested.editForm;

let dataComp = {}
const values = []


export default class CustomEditGridComponent extends FieldComponent {
    /**
     * This is the default schema of your custom component. It will "derive"
     * from the base class "schema" and extend it with its default JSON schema
     * properties. The most important are "type" which will be your component
     * type when defining new components.
     *
     * @param extend - This allows classes deriving from this component to
     *                 override the schema of the overridden class.
     */
    static schema(...extend) {
        return FieldComponent.schema({
                type: "editGridCustom",
                label: "CollectionBasedEditGrid",
                key: "editGridCustom",

            },
            ...extend
        );
    }

    /**
     * This is the Form Builder information on how this component should show
     * up within the form builder. The "title" is the label that will be given
     * to the button to drag-and-drop on the buidler. The "icon" is the font awesome
     * icon that will show next to it, the "group" is the component group where
     * this component will show up, and the weight is the position within that
     * group where it will be shown. The "schema" field is used as the default
     * JSON schema of the component when it is dragged onto the form.
     */
    static get builderInfo() {
        return {
            title: "CollectionBasedEditGrid",
            icon: "html",
            group: "basic",
            documentation: "/userguide/#textfield",
            weight: 0,
            schema: CustomEditGridComponent.schema(),
        };
    }

    /**
     * Called when the component has been instantiated. This is useful to define
     * default instance variable values.
     *
     * @param component - The JSON representation of the component created.
     * @param options - The global options for the renderer
     * @param data - The contextual data object (model) used for this component.
     */
    constructor(component, options, data) {
        super(component, options, data);
    }

    /**
     * Called immediately after the component has been instantiated to initialize
     * the component.
     */
    init() {
        window.isLoaded = false;




        super.init();
    }


    /**
     * This method is used to render a component as an HTML string. This method uses
     * the template system (see Form Templates documentation) to take a template
     * and then render this as an HTML string.
     *
     * @param content - Important for nested components that receive the "contents"
     *                  of their children as an HTML string that should be injected
     *                  in the {{ content }} token of the template.
     *
     * @return - An HTML string of this component.
     */
    render(_content) {


        let _componentApiKey = this.component.apiKey
        let _componentCollectionId = this.component.collectionId


        let table = ' <table id="' + _componentApiKey + '" class="display table table-striped table-bordered order-column hover" style="width:100%"> </table><button class="btn btn-primary" ref="addNewRef"><i class="fa fa-plus"></i> Hinzufügen</button>';


        this.loadData();





        return super.render(table);




    }


    loadData(reload = false) {
        let self = this;

        let _componentApiKey = this.component.apiKey
        let _componentCollectionId = this.component.collectionId
        let _itemsPerPage = (this.component.itemsPerPage) ? this.component.itemsPerPage : 25
        let isCustomFields = false;
        let query = {};
        query.query = "usf_DataCollectionWithItemsForOnlyCommunity(" + _componentCollectionId + "," + window.GlobalVueInstance.$root.$children[0].userData.Id + "," + window.GlobalVueInstance.$root.$children[0].userData.communityId + ")"
        $.fn.dataTable.ext.errMode = 'none';
        $.fn.dataTableExt.sErrMode = 'none';
        DataTable.ext.errMode = 'none';

        window.GLOBALAXIOS
            .post(window.GLOBALCONFIG.API_URL + "/api/dynamicQuery/", query)
            .then((response) => {


                let _collectionBaseData = JSON.parse(response.data)[0];
                let fields = (self.component.data) ? self.component.data.labelFields : JSON.parse(_collectionBaseData.fields).fields;


                let _mappedFields = [];
                if (self.component.data && self.component.data.labelFields) {
                    _mappedFields = self.component.data.labelFields.map(e => ({ title: e.title, key: e.field }))
                    isCustomFields = true;
                } else {
                    _mappedFields = fields.filter(e => !e.isHiddenField && (e.isOverview || e.isOverview == undefined)).map(e => ({
                        title: e.title,
                        displayFields: e.displayFields,
                        key: e.key,
                        type: e.type,
                        "defaultContent": "<i> - </i>",
                        "render": function(data) {

                            if (e.type == "blob") {


                                if (!Array.isArray(data)) {

                                    if (data != undefined && data.filePath != undefined) {
                                        return '<img src="' + window.GlobalVueInstance.$root.$children[0].replacePath(data.filePath) + '" width="60px">';
                                    }
                                    return "<i> - </i>";
                                } else {
                                    return "<i> - </i>";
                                }
                            } else {

                                if (data != null && data != undefined && data != "") {
                                    if (data === true) {
                                        return "Ja"
                                    } else if (data === false) {
                                        return "Nein"
                                    }
                                    return data;
                                } else {
                                    return "<i> - </i>";

                                }
                            }
                        },

                    }));


                }

                _mappedFields.unshift({ title: "id", key: "collectionValueId" })
                _mappedFields.push({
                    data: null,
                    className: 'dt-center editButton',
                    defaultContent: '<i class="fa fa-pencil"/>',
                    orderable: false
                });
                _mappedFields.push({
                    data: null,
                    className: 'dt-center deleteButton',
                    defaultContent: '<i class="fa fa-trash"/>',
                    orderable: false
                })


                let keyValues = [];

                let collectionData = [];
                if (_collectionBaseData.items != null) {

                    collectionData = _collectionBaseData.items.map(function(e) {
                        let _data = [];
                        let value = JSON.parse(e.value);


                        _mappedFields.forEach(function(item) {


                            if (item.key == "collectionValueId" && value[item.key] == undefined) {

                            } else if (item.key == "foreignCollection") {

                            } else {

                                if (typeof value[item.key] == "object" && !Array.isArray(value[item.key])) {

                                    if (value[item.key] != null) {
                                        if (isCustomFields) {
                                            _data.push(value[item.key])
                                        } else {
                                            _data.push(value[item.key].text)
                                        }

                                    } else {
                                        _data.push("-");
                                    }
                                } else if (typeof value[item.key] == "array") {} else {

                                    if (e.fullItems != null && e.fullItems != undefined && item.type != undefined && item.type.includes('foreign')) {
                                        let foundFullItems = e.fullItems.find(e => e.key == item.key);
                                        if (foundFullItems != null) {

                                            var orgValue = JSON.parse(foundFullItems.orgValue)

                                            let displayValue = []
                                            if (fields != null && fields != undefined && fields.length > 0) {
                                                fields.forEach(function(field) {
                                                    displayValue.push(orgValue[field])
                                                })
                                            } else {
                                                displayValue.push(JSON.stringify(orgValue));
                                            }
                                            _data.push(displayValue.join("-"))
                                        } else {
                                            _data.push(value[item.key])
                                        }
                                    } else {


                                        _data.push(window.GlobalVueInstance.$root.$children[0].resolvePath(item.key, value))
                                    }







                                }

                            }

                        })

                        if (JSON.parse(e.value)["collectionValueId"] == null || JSON.parse(e.value)["collectionValueId"] == undefined) {
                            _data.unshift(e.collectionValueId)

                        }
                        //     

                        return _data;

                    })
                }

                let _columns = _mappedFields
                $(document).ready(function() {
                    let table = null;


                    if (!reload) {

                        table = new DataTable('#' + _componentApiKey, {
                            retrieve: true,
                            language: {
                                url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/de-DE.json',
                            },

                            displayLength: _itemsPerPage,
                            "aLengthMenu": [
                                [5, 10, 15, 45, -1],
                                [5, 10, 15, 45, "Alle"]
                            ],
                            columns: _columns,
                            data: collectionData

                        });
                        table.on('click', 'td.dt-control', function(e) {
                            let tr = e.target.closest('tr');
                            let row = table.row(tr);

                            if (row.child.isShown()) {
                                // This row is already open - close it
                                row.child.hide();
                            } else {
                                // Open this row
                                row.child(format(row.data())).show();
                            }
                        });
                    } else {
                        if (table == null) {
                            table = new DataTable('#' + _componentApiKey, {
                                retrieve: true


                            });
                        }
                        if (table != null) {
                            table.clear();
                            table.rows.add(collectionData);
                            table.draw();
                        }
                    }

                    $('#' + _componentApiKey).off("click", 'tbody .editButton').on('click', 'tbody .editButton', function() {


                        if (table == null) {
                            table = new DataTable('#' + _componentApiKey, {
                                retrieve: true


                            });
                        }

                        var data_row = table.row($(this).closest('tr')).data();
                        let data = _collectionBaseData.items.find(e => e.collectionValueId == data_row[0]);

                        window.GlobalVueInstance.$root.$children[0].editSingleCollectionItem({ id: data.collectionValueId, value: JSON.parse(data.value) }, function() {
                            self.loadData(true);
                        })


                    })

                    $('#' + _componentApiKey).off("click", 'tbody .deleteButton').on('click', 'tbody .deleteButton', function() {

                        if (table == null) {
                            table = new DataTable('#' + _componentApiKey, {
                                retrieve: true


                            });
                        }
                        var data_row = table.row($(this).closest('tr')).data();

                        let data = _collectionBaseData.items.find(e => e.collectionValueId == data_row[0]);

                        window.GlobalVueInstance.$root.$children[0].deleteCollectionItem({ id: data.collectionValueId }, function() {
                            self.loadData(true);

                        })


                    })



                    table.on('error.dt', function(e, settings, techNote, message) {
                        console.log('An error has been reported by DataTables: ', message);
                    })

                })
            });


    }



    edititem(event) {}
    addNewClick(event) {

        let self = this;

        let communityId = window.GlobalVueInstance.$root.$children[0].communityData._id;
        let _componentCollectionId = this.component.collectionId




        window.GLOBALAXIOS
            .get(window.GLOBALCONFIG.API_URL + "/api/communityById/" + communityId)
            .then((response) => {
                window.GlobalVueInstance.$root.$children[0].addSingleCollectionItem({ communityId: response.data, pluginData: { setting: { collectionId: _componentCollectionId } } }, function() {
                    self.loadData(true);

                });

            });

    }

    /**
     * The attach method is called after "render" which takes the rendered contents
     * from the render method (which are by this point already added to the DOM), and
     * then "attach" this component logic to that html. This is where you would load
     * any references within your templates (which use the "ref" attribute) to assign
     * them to the "this.refs" component variable (see comment below).
     *
     * @param - The parent DOM HtmlElement that contains the component template.
     *
     * @return - A Promise that will resolve when the component has completed the
     *           attach phase.
     */
    attach(element) {
        /**
         * This method will look for an element that has the 'ref="customRef"' as an
         * attribute (like <div ref="customRef"></div>) and then assign that DOM
         * element to the variable "this.refs". After this method is executed, the
         * following will point to the DOM element of that reference.
         *
         * this.refs.customRef
         *
         * For DOM elements that have multiple in the component, you would make this
         * say 'customRef: "multiple"' which would then turn "this.refs.customRef" into
         * an array of DOM elements.
         */


        this.loadRefs(element, {

            addNewRef: 'single',



        });


        /*    this.addEventListener(this.refs.editRef, 'click', () => {
                console.log('Custom Ref has been clicked!!!');        
            });
          
          */
        const superAttach = super.attach(element);
        this.attachButton();
        return superAttach;






    }

    /**
     * Called when the component has been detached. This is where you would destroy
     * any other instance variables to free up memory. Any event registered with
     * "addEventListener" will automatically be detached so no need to remove them
     * here.
     *
     * @return - A Promise that resolves when this component is done detaching.
     */
    detach() {

        return super.detach();
    }

    /**
     * Called when the component has been completely "destroyed" or removed form the
     * renderer.
     *
     * @return - A Promise that resolves when this component is done being destroyed.
     */
    destroy() {

        return super.destroy();
    }


    /**
     * Returns the value of the "view" data for this component.
     *
     * @return - The value for this whole component.
     */
    getValue() {



    }

    attachButton() {
        this.addEventListener(this.refs.addNewRef, 'click', this.addNewClick.bind(this));


    }


}



CustomEditGridComponent.editForm = function() {
    return {
        components: [

            {
                key: 'components',
                type: 'tabs',
                input: true,
                label: 'Tabs',
                weight: 50,
                reorder: true,
                components: [{


                        key: 'customEditGridData',
                        label: 'Data',
                        components: [



                            {
                                type: 'textfield',
                                key: 'apiKey',
                                label: 'API-Key',
                                validate: {
                                    required: true,
                                },
                            },
                            {
                                type: 'textfield',
                                key: 'collectionId',
                                label: 'CollectionId',

                            },

                            /*  {
                                  type: 'textfield',
                                  key: 'customQuery',
                                  label: 'Custom User Defined Function',

                              },*/
                            {
                                type: 'number',
                                key: 'itemsPerPage',
                                label: 'Einträge pro Seite'
                            },
                            {
                                type: 'datagrid',
                                label: 'LabelFields',
                                key: 'data.labelFields',
                                weight: 5,
                                reorder: true,
                                defaultValue: [{ field: '' }],
                                components: [{
                                        label: 'Title',
                                        key: 'title',
                                        input: true,
                                        width: 200,
                                        type: 'textfield',
                                    }, {
                                        label: 'Feld',
                                        key: 'field',
                                        input: true,
                                        width: 200,
                                        type: 'textfield',
                                    },

                                ],

                            },
                            {
                                type: 'textfield',
                                key: 'label',
                                label: 'Label'
                            },
                            {
                                type: 'checkbox',
                                key: 'hideLabel',
                                label: 'Hide Label'
                            },
                        ]
                    }

                ]
            },

        ]
    };
}



Formio.Components.addComponent("editGridCustom", CustomEditGridComponent);